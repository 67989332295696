export default {
  dark: {
    colors: {
      background: '#000',
      background2: '#0e0e0e',
      background3: '#2f2f2f',
      text: '#fff',
      poligraficiGreen: '#1fac77',
      textOff: '#7d7d7d',
      link: '#0d8ecf',
      separator: '#2f2f2f',
    },
  },
  light: {
    colors: {
      background: '#fff',
      background2: '#e3e3e3',
      background3: '#c8c8c8',
      text: '#000',
      poligraficiGreen: '#1fac77',
      textOff: '#636363',
      link: '#0d8ecf',
      separator: '#2f2f2f',
    },
  },
};
